import React from "react";
import { Helmet } from "react-helmet";
import { Container, Typography, Box, Button } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import Logo from "../icons/logo";
import useLocalizedLocationPath from "../../hooks/use-localized-location-path";

type Props = RouteComponentProps<Record<string, unknown>> & {
  noLogo?: boolean;
};

export default function NotFound({ noLogo }: Props) {
  const getLocalizedPath = useLocalizedLocationPath();

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Not Found | AIRGRAFT 2</title>
      </Helmet>
      <Box
        bgcolor="common.white"
        borderRadius="1rem"
        padding={{ xs: 4, md: 8 }}
        marginY={{ xs: 4, md: 8 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!noLogo && (
          <Box width="150px" marginBottom={4}>
            <Logo />
          </Box>
        )}

        <Box
          display="flex"
          alignItems="center"
          marginBottom={4}
          textAlign="center"
        >
          <Typography variant="h5">Sorry, nothing to be found here</Typography>
        </Box>
        <Typography
          color="textSecondary"
          style={{ maxWidth: "635px", textAlign: "center" }}
        >
          {`The link you clicked may be broken or the page have been removed. Please go to the home page by clicking the button bellow.`}
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          flexDirection={{ xs: "column", md: "row" }}
          marginTop={6}
        >
          <Box width={{ xs: "100%", md: "250px" }}>
            <a href={getLocalizedPath("/")} style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                fullWidth
                style={{
                  backgroundColor: "#fff"
                }}
              >
                Go back to home
              </Button>
            </a>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
